
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        








































































































































































.channels-grid__subtitle {
  @extend %fw-normal;

  color: $c-anthracite-light;
  font-family: $ff-alt;

  strong {
    @extend %fw-bold;
  }

  .abstract-background & {
    color: $white;
  }
}

.channels-grid__items {
  display: flex;
  flex-wrap: wrap;
}

.channels-grid__item {
  @include aspect-ratio(268, 134);

  margin-inline: 0.5rem;
  margin-bottom: 3rem;
  position: relative;
  width: calc(25% - 1rem);

  border: 1px solid $c-gray-lightest;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s $ease-out-quart;

  img {
    @include get-all-space;

    object-fit: contain;

    transition: opacity 0.3s $ease-out-quart;
  }

  .logo-nb {
    opacity: 0;
  }
  .logo-color {
    opacity: 1;
  }
  &:nth-child(-n + 36) {
    order: 8;
  }
  &:nth-child(-n + 32) {
    order: 7;
  }
  &:nth-child(-n + 28) {
    order: 6;
  }

  &:nth-child(-n + 24) {
    order: 5;
  }

  &:nth-child(-n + 20) {
    order: 4;
  }

  &:nth-child(-n + 16) {
    order: 3;
  }

  &:nth-child(-n + 12) {
    order: 2;
  }

  &:nth-child(-n + 8) {
    order: 1;
  }

  &:nth-child(-n + 4) {
    order: 0;
  }

  &:hover,
  &.is-active {
    background-color: $c-bunting;
  }

  &.is-active {
    &:after {
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      bottom: -3.1rem;
      left: 50%;
      transform: translateX(-50%);
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid $c-bunting;
    }
  }

  .abstract-background &,
  .abstract-background &:hover,
  .abstract-background &.is-active {
    background: $white;
  }
}

.channels-grid__details {
  overflow: hidden;
  margin-bottom: 2rem;
  width: 100%;
  height: auto;
  margin-inline: 0.5rem;

  color: $white;
  background-color: $c-bunting;
  border-radius: 8px;
  order: 0;

  .btn {
    margin-top: auto;
  }
}

.channels-grid__details-inner {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 2rem);

  @include mq(m) {
    flex-direction: row;
  }
}

.channels-grid__details__content {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  height: 100%;
  margin-inline: col(1);
  padding: 3.5rem 0;

  @include mq(m) {
    padding: 5rem 0;
  }
}

.channels-grid__details__title {
  margin-top: 0;
  margin-bottom: 2rem;
}

.channels-grid__details__description {
  margin-top: 0;
  margin-bottom: 2.5rem;
}

.channels-grid__details__number-outer {
  margin-bottom: 4.5rem;
}

.channels-grid__details__number {
  display: inline-flex;
  margin-left: 0.6rem;
  padding: 0.8rem 1.4rem;
  color: white;
  font-size: 1.6rem;
  line-height: 1;
  font-weight: 600;
  background: rgba(black, 0.4);
  border: 1px solid white;
  border-radius: 6px;
}

.channels-grid__details__picture {
  position: relative;
  flex: 0 0 col(5);
  background-color: $c-pink-lightest;

  img {
    @include image-fit;
  }
}

.btn {
  margin-top: auto;
  padding: 1.2rem * 0.75 $spacing;

  .svg {
    width: 1.8rem;
    height: 1.8rem;
  }
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

// Transition
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fadeHeight-enter-active,
.fadeHeight-leave-active {
  transition: all 0.6s $ease-out-quart;
  max-height: 40rem;
}
.fadeHeight-enter,
.fadeHeight-leave-to {
  opacity: 0;
  max-height: 0;
}
